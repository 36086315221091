<template>
    <div class="main">
        <div class="selected">
            <h1>Chosen</h1>
            <img :src="selectedSymbolTexture" alt="">
        </div>
        <div class="win_items">
            <h1>Result</h1>
            <div v-for="(s, index) in  symbols[0]" :key="index">
                <img :src="require(`@/assets/games/mini/hotline/` + s + `.png`)" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        symbols: {
            type: Array,
            required: true,
        },
        selectedSymbol: {
            type: String,
            required: true,
        },
    },
    computed: {
        selectedSymbolTexture() {
            return require(`@/assets/games/mini/hotline/` + this.selectedSymbol + `.png`)
        }
    },
    methods: {

    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', this.symbols[0].length * 85 + 30);
        this.$store.dispatch('workspace/setWidth', 200);
    },
}
</script>

<style scoped>
.main {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 0px 20px;
}

.selected {
    display: flex;
    flex-direction: column;
    align-self: center;
}
</style>
